import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import Seo from '../components/seo'

import styled from "@emotion/styled"

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 78vh;
`

const DescriptionContrainer = styled.div`
  padding-top: 2rem;
`
const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 0;
`

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <OuterContainer>
        <Container>
          <NameHeader>
            <Trans>Sandar Mo:Mo:</Trans>
          </NameHeader>
          <DescriptionContrainer>
            <Description>
              <Trans>Namaste</Trans>
            </Description>
            <Description>
              <Trans>Welcome to Sandar Mo:Mo: center</Trans>
            </Description>
            <Description>
              <Trans>We are serving since 1995</Trans>
            </Description>
          </DescriptionContrainer>
        </Container>
      </OuterContainer>
    </Layout>
  );
};

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
